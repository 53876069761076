import React, { useEffect } from "react";
import { Table } from 'react-bootstrap';
import {UserContext} from '../contexts/userContext';
import Utilites from "./Utilities";

const Installers = (props) => {

    let {userDetails} = React.useContext(UserContext);
    const [items, setItems] = React.useState([]);
    const [dataisLoaded, setDataisLoaded] = React.useState(false);

   

    let zip = props.zip;

    useEffect(() => {
        let settings = Utilites.getSettings();
        let serviceEventsHost = `https://api.${ settings.environment === 'production' ? '' : `${settings.environment}.`}installernet.com/serviceevents/v3`;
        const handleErrors = (response) => {            
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        }
    
        fetch(
            `${serviceEventsHost}/Installers?programId=${settings.programId}&location=${zip}`, {
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                Authorization: `Bearer ${userDetails.token}`
            }
        }
        )
        .then(handleErrors)
        .then((res) => res.json())
        .then((json) => {
           setItems(json);
           setDataisLoaded(true);
        })

    },[zip, userDetails.token]);

    return (
        <React.Fragment>
            { !dataisLoaded ? <div><h3> One moment.... </h3></div> : null }

            { dataisLoaded && items.length === 0 ? <div><h3>No installers in this area</h3></div> :

            <div className="App">
                <Table bordered hover size="sm" style={{verticalAlign: 'middle'}} >
                    <thead style={{ background: 'lightgray' }}>
                        <tr>
                            <th>Company</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Distance</th>
                        </tr>
                    </thead>
                    {items.map((item, idx) => (

                        <tbody key={idx}>
                            <tr>
                                <td>{item.companyName}</td>
                                <td>{item.city}</td>
                                <td>{item.state}</td>
                                <td>{item.distance} Mi</td>
                            </tr>
                        </tbody>

                    ))}
                </Table>
            </div>}
        </React.Fragment>
    )

}

export default Installers
