import Utilites from "../components/Utilities";

let settings = Utilites.getSettings();

let messageHost = `https://api.${ settings.environment === 'production' ? '' : `${settings.environment}.`}installernet.com/communications/v3`;

const communicationsApi = {
    token: String,
    sendOrderMessage: async (orderId, payload) => {

        let result = await fetch(`${messageHost}/messages/order/${orderId}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'text/json',
                Authorization: `Bearer ${communicationsApi.token}`
            },
            body: JSON.stringify(payload)
        });
        console.log(result);
        if (result.status === 200){
            return result.json();
        };
        return {error: `${result.status}: ${result.statusText}`};
    },
    sendMessage: async (payload) => {

        let result = await fetch(`${messageHost}/messages`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'text/json',
                Authorization: `Bearer ${communicationsApi.token}`
            },
            body: JSON.stringify(payload)
        });
        console.log(result);
        if (result.status === 200){
            return result.json();
        };
        return {error: `${result.status}: ${result.statusText}`};
    }
}


export default communicationsApi;