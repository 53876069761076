import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {UserContext} from '../contexts/userContext';
import communicationsApi from "../apis/communications";
import Utilites from "./Utilities";

let settings = Utilites.getSettings();

const NoOrderForm = (props) => {

    let { userDetails } = React.useContext(UserContext);
    const [radioCheck, setChecked] = useState('')
    function handleClick(event) {
        setChecked(event.target.value);
    }

    const handleCancel = (e) => {

        e.preventDefault()

        setFirstName('')
        setLastName('')
        setCompany('')
        setPhone('')
        setEmail('')
        setEmail('')
        setMessage('')
        setChecked('')
    }
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [company, setCompany] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const payload = {
        source: userDetails.name,          // Full Name of logged in user
        sourceServiceType: "Web",
        sourceReferenceId: userDetails.id,        // ID of ICE User logged in
        sourceReferenceType:userDetails.type,   // Defines the ReferenceId as being a Ice User 
        destinations: [
            {
                referenceType: userDetails.role,  // We are referencing an ICE Role, instead of a ICEUser, as the message is going to a group instead of a person
                referenceId: "CallCenter", // This is the name of the role for coordinators in ICE
                serviceType: "ICE",
                destination: "Call Center"
            }
        ],
        references: [
            {
                referenceId: "1541",
                referenceType: "ProgramId"
            }
        ],
        subject: `${firstName} ${lastName} has a question. Please call them at ${phone}.\n Other customer information \n Program: RealTruck \n Company: ${company} \n Email: ${email}`,
        contentType: "Text",
        contents: message,
        createdBy: userDetails.name,  // Username of logged in user
        pageSource: `https://www${ settings.environment === 'production' ? '' : `.${settings.environment}`}.realprosupport.com/message` // URL of the page the message is being sent from
    }

    const sendMessage = async (e) => {
        e.preventDefault();
        communicationsApi.token = userDetails.token;
        var result = await communicationsApi.sendMessage(payload);
        props.messageSent(result);
    }

    return (
        <Form >
            <Row>
                <Col>
                    <Form.Check type='radio' id='customer-check' label='Customer' value='Customer' onClick={handleClick} checked={radioCheck === 'Customer'} onChange={()=>setChecked('Customer')}/>
                </Col>
                <Col>
                    <Form.Check type='radio' id='realpro-check' label='RealPro' value='RealPro' onClick={handleClick} checked={radioCheck === 'RealPro'} onChange={()=>setChecked('RealPro')}/>
                </Col>
            </Row>
            <Form.Group as={Row} className="mb-3" controlId="firstName">
                {/* <Form.Label column sm={2} md={2} >
                    First Name
                </Form.Label> */}
                <Col sm={10} md={10} >
                    <Form.Control type="text" placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="lastName">
                {/* <Form.Label column sm={2}>
                    Last Name
                </Form.Label> */}
                <Col sm={10}>
                    <Form.Control type="text" placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="company">
                {/* <Form.Label column sm={2}>
                    Company
                </Form.Label> */}
                <Col sm={10}>
                    <Form.Control type="text" placeholder="Company" value={company} onChange={e => setCompany(e.target.value)} />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="phone">
                {/* <Form.Label column sm={2}>
                    Phone
                </Form.Label> */}
                <Col sm={10}>
                    <Form.Control type="text" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                {/* <Form.Label column sm={2}>
                    Email
                </Form.Label> */}
                <Col sm={10}>
                    <Form.Control type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="message">
                {/* <Form.Label column sm={2}>
                    Your Message
                </Form.Label> */}
                <Col sm={10}>
                    <Form.Control as="textarea" rows={3} placeholder="Message" value={message} onChange={e => setMessage(e.target.value)} />
                </Col>
            </Form.Group>
            <Button variant="secondary" type="cancel" style={{ margin: '10px', float: 'right' }} onClick={handleCancel} >
                Cancel
            </Button>
            <Button variant="primary" type="submit" style={{ margin: '10px', float: 'right' }} onClick={sendMessage}  >
                Submit
            </Button>

        </Form>
    )
}

export default NoOrderForm