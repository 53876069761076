import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Faq from "../components/FAQ";
import HeaderService from '../components/HeaderService';

import './css/FAQPage.css'

const FAQpage = () => {
    return (
        <Fragment>
            <HeaderService label="FREQUENTLY ASKED QUESTIONS"/>
            <Container fluid className="col-xs-12 col-md-10 offset-md-1 mt-4" style={{ flex:'auto'}} >
                <Row>
                    <Col>
                        <Faq />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default FAQpage