import React, { useEffect, useState } from "react";
import { Accordion } from 'react-bootstrap';
import {UserContext} from '../contexts/userContext';
import Utilites from "./Utilities";
import DOMPurify from "dompurify";

const Faq = (props) => {

    const [items, setItems] = useState([])
    const [categories, setCategories] = useState([])
    const [dataisLoaded, setDataisLoaded] = useState(false)
    const sanitizer = DOMPurify.sanitize;

    let {userDetails} = React.useContext(UserContext);


    const authorization = 'd2VhdGhlcmZsb3d1c2VyOlEyM1NiNiph';
    let settings = Utilites.getSettings();

    let faqhost = `https://faqapi.${ settings.environment === 'production' ? '' : `${settings.environment}.`}installernet.com/api/faqs`;

    // initial load of component, grab list of categories and product mappings
    useEffect(() => {
        fetch(
            `${faqhost}?categoryid=${userDetails.type === 'Installer' ? '88' : '81'}&includesubcategories=true`, {
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                Authorization: `Basic ${authorization}`
            }
        }
        )
            .then((res) => res.json())
            .then((json) => {
                setItems(json);
                setCategories([...new Set(json.map(p=>p.categoryName))]);
                setDataisLoaded(true);
            });
    },[userDetails, faqhost])

    return (<div className="faq-accordian" >
             {!dataisLoaded ? (<p>Loading...</p>) :
             categories.map((category, idx) => {
                return (
                    <div key={idx}>
                        <h4>{category}</h4>
                        { items.filter(data => data.categoryName === category).map((item, idx2) => (
                            <Accordion key={idx2}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{item.question}</Accordion.Header>
                                    <Accordion.Body dangerouslySetInnerHTML={{__html: sanitizer(item.answer)}}>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>))}
                            <br />
                    </div>
                )
            })}
        </div>);

}

export default Faq;