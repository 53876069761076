import React, { useState, useEffect } from "react";
import { Button, Col, Form, Card } from "react-bootstrap";
import marketplaceApi from "../apis/marketplace";
import {UserContext} from '../contexts/userContext';
import toast, { Toaster } from 'react-hot-toast';


const NewOrder = () => {
    let emptyDetail = {};
    emptyDetail["Vehicle Make"] = ""
    emptyDetail["Vehicle Model"] = ""
    emptyDetail["Vehicle Year"] = ""
    emptyDetail["Vehicle Bed Length"] = ""
    emptyDetail["Vehicle Body Type"] = ""
    emptyDetail["Vehicle Engine"] = ""

    const emptyItem = {
        category: "",
        productBrand: "",
        productModel: "",
        itemSku: "",
        serviceZip: "",
        details: {...emptyDetail}
    };
    const emptyOrder = {
        status: null,
        purchaseDate: null,
        name: null,
        address1: "",
        address2: null,
        city: "",
        stateOrProvince: "",
        postalCode: "",
        countryName: "US",
        salesOrderNumber: null,
        email: "",
        orderNumber: "",
        submitted: false,
        details: {
            email:""
        },
        items: []
    };

    const [data, setData] = useState([])
    const [quote, setQuote] = useState({})
    const [transaction, setTransaction] = useState({})
    const [order, setOrder] = useState({...emptyOrder});
    const [item, setItem] = useState({...emptyItem});

    let {userDetails} = React.useContext(UserContext);

    // initial load of component, grab list of categories and product mappings
    useEffect(() => {
        const getListings = async () =>{
            var listings = await marketplaceApi.getListings(userDetails.token, 1018);
            var productMappings = await marketplaceApi.getProductMappings(userDetails.token, 1018);
            var products = productMappings.map(p=>{
                p.listing = listings.find(l=>l.merchantItemID === p.itemId);
                return p;
            });
            setData(products);
        }
        getListings();
    },[userDetails])

    const itemFieldChange = (e) => {
        let currentItem = {...item};
        currentItem[e.target.id] = e.target.value;
        
        setItem({...currentItem});
    }
    const itemFieldDetailChange = (e) => {
        let currentItem = {...item};
        currentItem.details[e.target.id] = e.target.value;
       
        setItem({...currentItem});
    }
    const orderFieldChange = (e) => {
        let currentOrder = {...order};
        currentOrder[e.target.id] = e.target.value;
        console.log(currentOrder);
        setOrder({...currentOrder});
    }
    const orderDetailFieldChange = (e) => {
        let currentOrder = {...order};
        currentOrder.details[e.target.id] = e.target.value;
        console.log(currentOrder);
        setOrder({...currentOrder});
    }
    const startOrder = async (e) => {
         e.preventDefault();
        let newOrder = {...order};
        newOrder.status = 'Partial';
        newOrder.purchaseDate = new Date();

        var createdOrder = await marketplaceApi.createOrder(userDetails.token, newOrder);

        setOrder({...createdOrder, items: []});
        console.log(e);
    }
    const handleCategoryChange = (e) => {
        let newItem = {...item};
        newItem.category = e.target.value;
        setItem({...newItem});
    }    
    const addProduct = async (e) =>{
        e.preventDefault();
        var quote = await marketplaceApi.createQuote(userDetails.token, item);
        if (quote.id !== '00000000-0000-0000-0000-000000000000'){
            var trans = await marketplaceApi.createTransaction(userDetails.token, {quoteId: quote.id, status: "Sold"}, order.id);
            let o = {...order};
            o.items.push(item);
            setQuote(quote);
            setOrder({...order});
            setTransaction({...trans});
            setItem({...emptyItem});
        }else if(!quote.productInstallationAvailable) {
            toast.error('Product installation not available.')
        }else if(!quote.serviceProvidersAvailable) {
            toast.error('Service not available in this area.')
        }else{
            toast.error('Invalid data.')
        }
    }
    const submitOrder = async (e) =>{
        e.preventDefault();
        var quote = await marketplaceApi.submitOrder(userDetails.token, order.id);
        if (quote.id){
            setOrder({...emptyOrder});
            setTransaction({});
            setItem({...emptyItem});
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

    if (data === undefined) {
        return <>Still loading...</>;
      }
    return (
        <Col  xs={12} md={4} className='service-dropdown border rounded'  >
            <Card style={{ border: "none" }}>
                <Card.Body>
                    <Card.Title>Create New Order</Card.Title>
                    <Form>
                        <Toaster />
                        { !order.salesOrderNumber ? (
                        <React.Fragment>
                            <Form.Control type="text" id="name" required placeholder="Name" style={{ textAlign: 'center', marginBottom: "16px" }} value={order.name} onChange={orderFieldChange}/>
                            <Form.Control type="text" id="address1" required placeholder="Address" style={{ textAlign: 'center', marginBottom: "16px" }} value={order.address1} onChange={orderFieldChange}/>
                            <Form.Control type="text" id="city" required placeholder="City" style={{ textAlign: 'center', marginBottom: "16px" }} value={order.city} onChange={orderFieldChange}/>
                            <Form.Control type="text" maxLength={2} id="stateOrProvince" required placeholder="State" style={{ textAlign: 'center', marginBottom: "16px" }} value={order.stateOrProvince} onChange={orderFieldChange}/>
                            <Form.Control type="number" maxLength={5} id="postalCode" required placeholder="Zip" style={{ textAlign: 'center', marginBottom: "16px" }} value={order.postalCode} onChange={orderFieldChange}/>
                            <Form.Control type="number" maxLength={10} id="phone" required placeholder="Phone" style={{ textAlign: 'center', marginBottom: "16px" }} value={order.phone} onChange={orderFieldChange}/>
                            <Form.Control type="text" id="email" required placeholder="Email" style={{ textAlign: 'center', marginBottom: "16px" }} value={order.details.email} onChange={orderDetailFieldChange}/>

                            <Button type="submit" disabled={!item} variant='warning' onClick={startOrder} className="mb-2" style={{ backgroundColor: '#ffc712' }}>
                                Start new order
                            </Button>
                        </React.Fragment>)
                        :null}

                        { order.salesOrderNumber ? (
                        <React.Fragment>
                            <div>
                                Order Number: {order.salesOrderNumber}  Number of Products: {order.items ? order.items.length : 0}
                            </div>
                            <Form.Select aria-label="Default select example" required style={{ textAlign: 'center', marginBottom: "16px" }} value={item.category}  onChange={handleCategoryChange} >
                                <option value="" disabled>Select Category</option>
                                {data.map((data, i) => data.listing && data.listing.category ? (
                                    <option key={i} value={data.category} >{data.listing.category}</option>
                                ):null)}
                            </Form.Select>
                            <Form.Control type="text" id="productBrand" required placeholder="Brand" style={{ textAlign: 'center', marginBottom: "16px" }} value={item.productBrand} onChange={itemFieldChange}/>
                            <Form.Control type="text" id="productModel" required placeholder="Model" style={{ textAlign: 'center', marginBottom: "16px" }} value={item.productModel} onChange={itemFieldChange}/>
                            <Form.Control type="text" id="itemSku" required placeholder="SKU#" style={{ textAlign: 'center', marginBottom: "16px" }} value={item.itemSku} onChange={itemFieldChange}/>
                            <Form.Control type="number" id="serviceZip" required placeholder="ZipCode" style={{ textAlign: 'center', marginBottom: "16px" }} value={item.serviceZip} onChange={itemFieldChange}/>
                            <Form.Control type="text" id="Vehicle Make" required placeholder="Vehicle Make" style={{ textAlign: 'center', marginBottom: "16px" }} value={item.details["Vehicle Make"]} onChange={itemFieldDetailChange}/>
                            <Form.Control type="text" id="Vehicle Model" required placeholder="Vehicle Model" style={{ textAlign: 'center', marginBottom: "16px" }} value={item.details["Vehicle Model"]} onChange={itemFieldDetailChange}/>
                            <Form.Control type="number" id="Vehicle Year" required placeholder="Vehicle Year" style={{ textAlign: 'center', marginBottom: "16px" }} value={item.details["Vehicle Year"]} onChange={itemFieldDetailChange}/>
                            <Form.Control type="text" id="Vehicle Bed Length" required placeholder="Vehicle Bed Length" style={{ textAlign: 'center', marginBottom: "16px" }} value={item.details["Vehicle Bed Length"]} onChange={itemFieldDetailChange}/>
                            <Form.Control type="text" id="Vehicle Body Type" required placeholder="Vehicle Body Type" style={{ textAlign: 'center', marginBottom: "16px" }} value={item.details["Vehicle Body Type"]} onChange={itemFieldDetailChange}/>
                            <Form.Control type="text" id="Vehicle Engine" required placeholder="Vehicle Engine" style={{ textAlign: 'center', marginBottom: "16px" }} value={item.details["Vehicle Engine"]} onChange={itemFieldDetailChange}/>

                            <Button type="submit" disabled={!order} variant='warning' onClick={addProduct} className="mb-2" style={{ backgroundColor: '#ffc712' }}>
                                Add Product to Order
                            </Button>
                            <Button type="submit" disabled={!transaction.id} variant='warning' onClick={submitOrder} className="mb-2" style={{  backgroundColor: '#ffc712' }}>
                                Submit Order
                            </Button>
                        </React.Fragment>)
                        :null}
                        { order.submitted ? (
                            <React.Fragment>
                                <table style={{margin: '0 auto', textAlign: 'right'}}>
                                    <tbody>
                                        <tr>
                                            <td>Regular price:</td>
                                            <td>{formatter.format(quote.regularPrice)}</td>
                                        </tr>
                                        <tr>
                                            <td>Discount:</td>
                                            <td>{formatter.format(quote.discount)}</td>
                                        </tr>                                    
                                        <tr>
                                            <td>Price:</td>
                                            <td>{formatter.format(quote.sellPrice)}</td>
                                        </tr>  
                                    </tbody>                              
                                </table>
                            </React.Fragment>):null}
                    </Form>
                </Card.Body>
            </Card>

        </Col>
    )
}

export default NewOrder