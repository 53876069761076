import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import MainMenu from './pages/MainMenu';
import FAQpage from './pages/FAQpage';
import Footer from "./components/Footer"
import LoginPage from './pages/LoginPage';
import MessagePage from './pages/MessagePage';
import RealproNetwork from './pages/RealproNetwork';
import ServicesPage from './pages/ServicesPage';
import {UserContext} from './contexts/userContext';

import './App.css';

function App() {

  const [userDetails, setUserDetails] = useState({
    token: null,
    id: null,
    name: null,
    type: null,
    role: null
  });
  
  const RequireAuth = ( {children }) => {
    let location = useLocation();
  
    if (!userDetails.token) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      console.log('no access');
      return <Navigate to="/login" state={{ from: location }} />;
    }
  
    return children;
  }

  return (
      <div className="App">
        <UserContext.Provider value={{userDetails, setUserDetails}}>
          <Router>
              <Routes>
                <Route exact path='/login' element={<LoginPage />} />
                <Route path='/' element={<RequireAuth><MainMenu /></RequireAuth>} />
                <Route path='/network' element={<RequireAuth><RealproNetwork /></RequireAuth>} />
                <Route path='/faq' element={<RequireAuth><FAQpage /></RequireAuth>} />
                <Route path='/message' element={<RequireAuth><MessagePage /></RequireAuth>} />
                <Route path='/services' element={<RequireAuth><ServicesPage /></RequireAuth>} />
              </Routes>
            </Router>
        </UserContext.Provider>
        <Footer style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}/>
      </div>
  );
}

export default App;
