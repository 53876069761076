import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {UserContext} from '../contexts/userContext';
import communicationsApi from "../apis/communications";
// import userCache from "./cache";
import Utilites from "./Utilities";

let settings = Utilites.getSettings();

const HaveOrderForm = (props) => {

    const [customerName, setCustomerName] = useState(props.data.name ?? "")
    const [primaryPhone, setPrimaryPhone] = useState(props.data.phone ?? "")
    const [alternatePhone, setAlternatePhone] = useState('')
    const [message, setMessage] = useState('')
    const realTruck = 'RealTruck'
    let {userDetails} = React.useContext(UserContext);

    const payload = {
        source: userDetails.name,          // Full Name of logged in user
        sourceServiceType: "Web",
        sourceReferenceId: userDetails.id,        // ID of ICE User logged in
        sourceReferenceType:userDetails.type,   // Defines the ReferenceId as being a Ice User 
        destinations: [
            {
                referenceType: userDetails.role,  // We are referencing an ICE Role, instead of a ICEUser, as the message is going to a group instead of a person
                referenceId: "CallCenter", // This is the name of the role for coordinators in ICE
                serviceType: "ICE",
                destination: "Call Center"
            }
        ],
        references: [
            {
                referenceId: "123",   // referencing the clientid and programid so we can search by these in ICE if needed
                referenceType: "ClientId"
            }, {
                referenceId: "222",
                referenceType: "ProgramId"
            }
        ],
        subject: `${customerName} has a question. Please call them at ${primaryPhone}\n other customer information \n Program: ${realTruck} \n Alternate Phone: ${alternatePhone} \n Message: ${message}`,
        contentType: "Text",
        contents: message,
        createdBy: userDetails.name,  // Username of logged in user
        pageSource: `https://www${ settings.environment === 'production' ? '' : `.${settings.environment}`}.realprosupport.com/message`  // URL of the page the message is being sent from
    }

    const sendMessage = async (e) => {
        e.preventDefault();
        communicationsApi.token = userDetails.token;
        var result = await communicationsApi.sendOrderMessage(props.orderId, payload);
        props.messageSent(result);
    }

    if (!props.data) return false
    return (
        <React.Fragment>
            <Form >
                <Form.Group as={Row} className="mb-3" controlId="program">
                    <p>{props.data.name}</p>
                    <Col sm={10} md={10} >
                        <Form.Control type="text" placeholder="RealTruck" value={realTruck} disabled />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="customerName">
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="Customer Name" value={props.data.name ?? ""} onChange={e => setCustomerName(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="primaryPhone">
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="Primary Phone" value={primaryPhone} onChange={e => setPrimaryPhone(e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="alternatePhone">
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="Alternate Phone" value={alternatePhone} onChange={e => setAlternatePhone(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="message">
                    <Col sm={10}>
                        <Form.Control as="textarea" rows={3} placeholder="Message" value={message} onChange={e => setMessage(e.target.value)} />
                    </Col>
                </Form.Group>
                <Button variant="primary" type="submit" style={{ margin: '10px', float: 'right' }} onClick={sendMessage}  >
                    Submit
                </Button>
            </Form>
        </React.Fragment>
    )
}

export default HaveOrderForm