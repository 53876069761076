import React, { Fragment } from "react"
import { Container, Row, Col } from "react-bootstrap"

import CustomerButton from "../components/CustomerButton"

import './css/CustomerService.css'
import Tools from "../images/icons8-tools-100.png"
import Question from "../images/icons8-question-mark-100.png"
import Group from "../images/icons8-user-groups-100.png"
import Schedule from "../images/icons8-calendar-100.png"
import Message from "../images/icons8-chat-message-100.png"
import Assigned from "../images/icons8-task-completed-100.png"
import Classroom from "../images/icons8-classroom-100.png"
import Money from "../images/icons8-money-transfer-100.png"
import HeaderService from '../components/HeaderService';
import Utilites from "../components/Utilities"
import {UserContext} from '../contexts/userContext';
import { useNavigate } from 'react-router-dom';

const MainMenu = (props) => {

    const nav = useNavigate()
    let {userDetails} = React.useContext(UserContext);

    let settings = Utilites.getSettings();

    return (
        <Fragment>
            <HeaderService label={userDetails.type === 'Installer' ? "INSTALLER PORTAL" : "CUSTOMER SERVICE"}/>
            <Container fluid className="col-xs-12 col-md-10 offset-md-1 mt-4" style={{ flex:'auto'}} >
                <Row style={{ width: '100%' }}>

                    <Col md={4}>
                        <a href='https://realtruck.com/realpro/' >
                            <CustomerButton id="One" imageName={Group} buttonName="WHAT IS THE REALPRO NETWORK"  />
                        </a>
                    </Col>
                    <Col md={4}>
                        <CustomerButton id="Two" imageName={Tools}  buttonName="AVAILABLE SERVICES AND REBATES" onClick={()=>nav('/services')} />
                    </Col>
                    <Col md={4}>
                        <CustomerButton id="Three" imageName={Question} buttonName="FREQUENTLY ASKED QUESTIONS"  onClick={()=>nav('/faq')} />
                    </Col>
                </Row>
                <Row style={{ width: '100%' }}>
                    <Col md={4}>
                        {userDetails.type === 'Installer' ? 
                            (<a target="_blank" rel="noreferrer" href={`https://installers.${ settings.environment === 'production' ? '' : `${settings.environment}.`}installernet.com/Pages/Members/openpurchaseorders.aspx?access_token=${userDetails.token}`} >
                                <CustomerButton id="Four" imageName={Assigned} buttonName="LOOKUP ASSIGNED INSTALLATIONS" />
                            </a>):
                            (<a href={`https://realpro.${ settings.environment === 'production' ? '' : `${settings.environment}.`}installernet.com/`} >
                                <CustomerButton id="Four" imageName={Schedule} buttonName="SCHEDULE OR LOOKUP INSTALLATION"  />
                            </a>)
                        }
                    </Col>
                    <Col md={4}>
                        {userDetails.type === 'Installer' ? 
                            (<a target="_blank" rel="noreferrer" href={`https://installers.${ settings.environment === 'production' ? '' : `${settings.environment}.`}installernet.com/Pages/ExpressPay.aspx?access_token=${userDetails.token}`} >
                                <CustomerButton id="Five" imageName={Money} buttonName="INSTALLATION EXPRESSPAY" />
                            </a>):
                            (<a target="_blank" rel="noreferrer" href={`/docs/training.pdf`} >
                                <CustomerButton id="Five" imageName={Classroom} buttonName="TRAINING DOCUMENTATION" />
                            </a>)
                        }
                    </Col>
                    <Col md={4}>
                        <CustomerButton id="Six" imageName={Message} buttonName="LEAVE MESSAGE FOR COORDINATOR" onClick={()=>nav('/message')} />
                    </Col>
                </Row>
            </Container>
        </Fragment>

    )
}

export default MainMenu