
import React, {useState} from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import Installers from "../components/Installers";
import {UserContext} from '../contexts/userContext';


const InstallerSearch = ()=>{
    const [showInstallers, setShowInstallers] = useState(false)
    const [zip, setZip] = useState('')
    const [searchZip, setSearchZip] = useState('')

    let {userDetails} = React.useContext(UserContext);
    
    const handleSearchInstallers = (e) => {
        e.preventDefault()
        setShowInstallers(true)
        setSearchZip(zip);
    }

    return(
        
        <Col xs={12} md={userDetails.username === 'ICEAdmin' ? 3 : 5} className="border rounded">
            <Card style={{ border: "none" }}>
                <Card.Body>
                    <Card.Title>INSTALLER SEARCH</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Enter Zip Code to find Installers</Card.Subtitle>
                    <Form  >
                        <Row className="align-items-center">
                            <Col xs="auto">
                                <Form.Label visuallyHidden>
                                    Zip Code
                                </Form.Label>
                                <InputGroup className="mb-2">
                                    <Form.Control type='zip' placeholder="Zip Code" value={zip} style={{ textAlign: 'center' }} onChange={(e) => setZip(e.target.value)} />
                                </InputGroup>
                            </Col>
                            <Col xs="auto">
                                <Button type="submit" variant='warning' className="mb-2" style={{ backgroundColor: '#ffc712', marginRight: '5px' }} onClick={handleSearchInstallers}  >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <div> {showInstallers && <Installers zip={searchZip} />} </div>
        </Col>
        )
}

export default InstallerSearch;