import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaPredicate } from 'react-media-hook'
import HeaderService from '../components/HeaderService';

import './css/RealproNetwork.css'

const RealproNetwork = () => {

    const smallerThan1200 = useMediaPredicate("(max-width: 1200px)")
    const smallerThan750 = useMediaPredicate("(max-width: 750px)")

    return (
        <Fragment>
            <HeaderService label="WHAT IS THE REALPRO NETWORK"/>
            <Container fluid style={{ marginTop: '15px' }}>
                <Row className="container-body" style={{justifyContent: 'center'}} >
                    <Col className="container-col" style={{maxWidth: smallerThan1200 ? (smallerThan750 ? '100vw' : '80vw') : '60vw'}} >
                        <p className="content">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis dolorum illo laudantium. Quod iure, ad ipsum a quibusdam eveniet eaque nesciunt quia eum dolorem error, culpa quae est accusantium saepe! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque, eveniet tempora aspernatur accusantium maxime iste dignissimos magni minima quos corrupti qui doloribus! Autem, explicabo? Placeat minima neque at harum earum! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, omnis consequuntur quis id assumenda harum aliquid in quae fugiat eaque minima totam velit optio laboriosam repudiandae! Fuga, ad doloremque? Officia.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default RealproNetwork