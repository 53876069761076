import React, { Fragment, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import HaveOrderForm from "../components/HaveOrderForm";
import NoOrderForm from "../components/NoOrderForm";
import rep from '../images/service-rep.jpg'
import HeaderService from '../components/HeaderService';
import {UserContext} from '../contexts/userContext';
import Utilites from "../components/Utilities";

//import warranty from '../images/warranty-starburst.png'
import './css/MessagePage.css'

const MessagePage = (props) => {

    let {userDetails} = React.useContext(UserContext);
    const [orderId, setOrderId] = useState('')
    const [messageSent, setMessageSent] = useState(null);
    const [showNoOrderMessage, setShowNoOrderMessage] = useState(false)

    const [data, setData] = useState('')

    let settings = Utilites.getSettings();

    let messageHost = `https://api.${ settings.environment === 'production' ? '' : `${settings.environment}.`}installernet.com/marketplace/v3`;
    
    const lookupOrder = async () => { 
        setMessageSent(null);
        setShowNoOrderMessage(false);

        let result = await fetch(`${messageHost}/orders?orderNumber=${orderId}`, {
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                Authorization: `Bearer ${userDetails.token}`
            }
        });
        
        if (result.status === 200){
            var order = await result.json();
            
            if (order.length >= 1){
                setMessageSent(null);
                setData(order[0]);
            }
        }
    }
    const messageSentComplete = (result)=>{
        console.log(result);
        setMessageSent(result);
        setData(null);
        setShowNoOrderMessage(false);
    }
    return (
        <Fragment>
            <HeaderService label="LEAVE MESSAGE FOR COORDINATOR"/>
            <Container fluid className="col-xs-12 col-md-10 offset-md-1 mt-4" style={{ flex:'auto'}} >
                <Row>
                    {/* <Row style={{ maxWidth: smallerThan1550 ? '100vw' : '85vw' }} > */}
                    <Row style={{ maxWidth: '85vw' }} >
                        <Col xs={12} sm={12} md={6} className="container-col">
                            <Card className="text-center" >
                                <Card.Text className='card-header'>
                                    Send Message
                                </Card.Text>
                                <Row>
                                    <div className="col-8 offset-2 mt-2 mb-2">
                                        <div className="d-flex">
                                            <Form.Control className="me-2" placeholder="ORDER #" value={orderId} onChange={e => setOrderId(e.target.value)}/>
                                            <Button variant="primary" id="button-order" onClick={lookupOrder} >                          
                                                Submit
                                            </Button                                            >
                                        </div>
                                    <div className="btn btn-link" onClick={()=>{setData(null); setMessageSent(null); setShowNoOrderMessage(true)}} id="no-order-button" >No order number?</div>
                                    {data ? <HaveOrderForm orderId={orderId} data={data} messageSent={messageSentComplete}/> : null}
                                    {showNoOrderMessage ? <NoOrderForm messageSent={messageSentComplete} /> : null}
                                    {messageSent ? !messageSent.error ? <p>Message Sent</p> : <p>Message Error - {messageSent.error}</p> : null}
                                    </div>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6} className="container-col">
                            <Card className="text-center" >
                                <Card.Text className='card-header'>
                                    Contact Us: 800-208-2921
                                </Card.Text>
                                <Card.Img variant="bottom" src={rep} style={{ borderRadius: '0 0 10px 10px', maxWidth: '200px', margin: 'auto' }} />
                            </Card>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </Fragment>
    )
}

export default MessagePage