import React, { useState, useContext } from 'react';
import { Button, Row, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../contexts/userContext';
import communicationsApi from '../apis/communications';
import Utilites from './Utilities';

const LoginForm = () => {

    const { setUserDetails } = useContext(UserContext);

    const [userName, setUserName] = useState('')
    const [pw, setPw] = useState('')
    const [loginError, setLoginError] = useState()

    const nav = useNavigate()

    let settings = Utilites.getSettings();

    let serviceEventsHost = `https://api.${ settings.environment === 'production' ? '' : `${settings.environment}.`}installernet.com/serviceevents/v3`;

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!userName || !pw) return;

        let result = await fetch(`${serviceEventsHost}/login`, {
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Access-Control-Allow-Origin": `https://${ settings.environment === 'production' ? 'www.' : `${settings.environment}.`}realprosupport.com/`,
                'Authorization': 'Basic ' + btoa(`${userName}:${pw}`)
            }
        });

        switch (result.status) {
            case 200:
                break;
            case 401:    
                setLoginError('Invalid Username/Password');
                break;
            default:
                setLoginError('Error Processing Login');
                break;
        }

        let userData = await result.json();
        
        setUserDetails({
            token : userData.token,
            id: userData.iceUser.id,
            username: userName,
            name: userData.iceUser.name,
            type: userData.iceUser.type,
            role: userData.iceUser.roles[0].role
        });
        
        // initialize apis
        communicationsApi.token = userData.token;
        
        // successful login goes to main menu, the menu will render based on user type
        if (userData.token) return nav('/')
    }

    return (

        <Form  >
            <Row className="">
                <div className="offset-2 col-8 offset-md-2 col-md-6">
                    <Form.Label visuallyHidden>
                        Username
                    </Form.Label>
                    <InputGroup className="col-12 mb-2">
                        <Form.Control type='text' placeholder="Username" value={userName} onChange={(e) => setUserName(e.target.value)} />
                    </InputGroup>
                    <Form.Label visuallyHidden>
                        Password
                    </Form.Label>
                    <InputGroup className="mb-2">
                        <Form.Control type='password' placeholder="Password" value={pw} onChange={(e) => setPw(e.target.value)} />
                    </InputGroup>
                    { loginError ? (<p style={{color: 'red'}}>{loginError}</p>) : null }
                </div>
                <div className="offset-2 col-8 offset-md-0 col-md-4 my-auto">
                    <Button type="submit" variant='warning' className="mb-2" style={{ backgroundColor: '##EEB91A' }} onClick={handleSubmit} >
                        Submit
                    </Button>
                </div>
            </Row>
        </Form>

    )
}

export default LoginForm