import React from "react";
import { Button } from "react-bootstrap"
import { useMediaPredicate } from 'react-media-hook'

const CustomerButton = (props) => {

    const smallerThan750 = useMediaPredicate("(max-width: 750px)")

    return (
        <Button type="button"
            className="CustomerButton"
            style={{
                backgroundColor: '#e9ecef',
                color: 'rgb(85 88 90)',
                margin: '28px 7px 14px',
                justifyContent: 'center',
                fontSize: '3.5vw',
                width: '80%',
                border: '1px solid',
                borderRadius: '5px',
                height: '90%'
            }}
            onClick={props.onClick}>
            <img src={props.imageName} alt=""></img>
            <p className="mt-1 mb-1" style={{ fontSize: smallerThan750 ? '150%' : '50%' }}>{props.buttonName}</p>
        </Button>
    )
}

export default CustomerButton