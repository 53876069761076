import Utilites from "../components/Utilities";

let settings = Utilites.getSettings();

let marketplacehost = `https://api.${ settings.environment === 'production' ? '' : `${settings.environment}.`}installernet.com/marketplace/v3`;

const marketplaceApi = {
    getHeaders: (token) => {
        return {
            "content-type": "application/json",
            "accept": "application/json",
            Authorization: `Bearer ${token}`
        };
    },
    getListings: async (token, accountId) => {
        let results = await fetch(`${marketplacehost}/listings?accountId=${accountId}`, {headers: marketplaceApi.getHeaders(token)});
        return results.json();
    },
    getProductMappings: async (token, accountId) => {
        let results = await fetch(`${marketplacehost}/products/mappings?accountId=${accountId}`, {headers: marketplaceApi.getHeaders(token)});
        return results.json();
    },
    createQuote: async (token, quoteReq) => {
        let request = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: marketplaceApi.getHeaders(token),
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(quoteReq) // body data type must match "Content-Type" header
          }
        let results = await fetch(`${marketplacehost}/quotes?saveQuote=true`, request);
        return results.json();
    },
    createOrder: async (token, order) => {
        let request = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: marketplaceApi.getHeaders(token),
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(order) // body data type must match "Content-Type" header
          }
        let results = await fetch(`${marketplacehost}/orders`, request);
        return results.json();
    },
    createTransaction: async (token, transaction, orderId) => {
        let request = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: marketplaceApi.getHeaders(token),
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(transaction) // body data type must match "Content-Type" header
          }
        let results = await fetch(`${marketplacehost}/orders/${orderId}/transactions`, request);
        return results.json();
    },
    submitOrder: async (token, orderId) => {
        let request = {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: marketplaceApi.getHeaders(token),
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({status: "Submitted"}) // body data type must match "Content-Type" header
          }
        let results = await fetch(`${marketplacehost}/orders/${orderId}`, request);
        return results.json();
    }
}

export default marketplaceApi;