import React from "react";
import { Col, Row } from 'react-bootstrap';
import logo from '../images/realpro-logo.png'
import Utilites from "./Utilities";

let settings = Utilites.getSettings();

const OneLogo = () => {
    return (
        <Row>
            <Col style={{ height: '12vh' }} >
                <a href={`https://${settings.environment === 'production' ? 'www' : settings.environment}.realprosupport.com/`}>
                <img src={logo} className="Header-Logo" alt="logo" style={{ paddingTop: '4%' }} />
                </a>
            </Col>
        </Row>
    )
}

export default OneLogo