import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useMediaPredicate } from 'react-media-hook'
import TwoLogos from './TwoLogo';
import OneLogo from './OneLogo';
import './css/Header.css'



const HeaderService = (props) => {

    const smallerThan750 = useMediaPredicate("(max-width: 750px)")

    return (
        <header className="App-header">
            <Container fluid>

                {smallerThan750 ? <OneLogo/> : <TwoLogos/> }

                <Row className='Top-Bar align-items-center'>
                    <h3 className="mb-1 mt-2">{props.label}</h3>
                </Row>
            </Container>
        </header>
    )
}

export default HeaderService