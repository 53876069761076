import React, { Fragment } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import are from '../images/ARE.png'
import bak from '../images/BAK.png'
import extang from '../images/Extang.png'
import lund from '../images/LUND.jpg'
import retrax from '../images/Retrax.png'
import rollNlock from '../images/Roll-N-Lock.png'
import rugged from '../images/rugged-ridge-logo.png'
import tonno from '../images/tonno-pro-logo.png'
import truxedo from '../images/truxedo.png'
import undercover from '../images/UnderCover.png'
import './css/ServicesPage.css'
import InstallerSearch from "../components/InstallerSearch";
import HeaderService from '../components/HeaderService';
import NewOrder from "../components/NewOrder";
import {UserContext} from '../contexts/userContext';

import Categories from "../components/Categories";
import ServiceRebateBar from "../components/ServiceRebateBar";

const ServicesPage = () => {

    let {userDetails} = React.useContext(UserContext);

    return (
        <>
            <HeaderService label="AVAILABLE SERVICES AND REBATES"/>
            <Container fluid className="col-xs-12 col-md-10 offset-md-1 mt-4" style={{ flex:'auto'}} >
                <Row className="container-body" style={{ justifyContent: 'center' }}>
                    <Row className="body-top mb-3 align-items-center">
                        <Col  className="service-col" >
                            <Image fluid src={are} className="services-top-pic" alt="logo" />
                        </Col>
                        <Col className="service-col" >
                            <Image fluid src={bak} className="services-top-pic" alt="logo" />
                        </Col>
                        <Col className="service-col" >
                            <Image fluid src={extang} className="services-top-pic" alt="logo" />
                        </Col>
                        <Col className="service-col" >
                            <Image fluid src={lund} className="services-top-pic" alt="logo" />
                        </Col>
                        <Col className="service-col" >
                            <Image fluid src={retrax} className="services-top-pic" alt="logo" />
                        </Col>
                        <Col className="service-col" >
                            <Image fluid src={rollNlock} className="services-top-pic" alt="logo" />
                        </Col>
                        <Col className="service-col" >
                            <Image fluid src={rugged} className="services-top-pic" alt="logo" />
                        </Col>
                        <Col className="service-col" >
                            <Image fluid src={tonno} className="services-top-pic" alt="logo" />
                        </Col>
                        <Col className="service-col" >
                            <Image fluid src={truxedo} className="services-top-pic" alt="logo" />
                        </Col>
                        <Col className="service-col" >
                            <Image fluid src={undercover} className="services-top-pic" alt="logo" />
                        </Col>
                    </Row>
                    <Row className="flex-d flex-d mb-3 justify-content-around" >  

                        <Categories />
                        <InstallerSearch/>
                        {userDetails.username === 'ICEAdmin' ? <NewOrder/> : null}

                    </Row>
                </Row>
            </Container>
            <ServiceRebateBar truckHeroLink={<a href="https://truckherorebates.com/Home.aspx" className="leftLink" >Truck Hero Rebates</a>} realTruckLink={<a href="https://realtruckrebates.com/Home.aspx" className="rightLink" >RealTruck Rebates</a>} />
        </>
    )
}

export default ServicesPage;