
const hostname = window && window.location && window.location.hostname;
var environment = 'production';

// determine hosted environment
if (hostname.includes('sandbox.')) {
    environment = 'sandbox';
} else if (hostname.includes('staging.')) {
    environment = 'staging';
} else if (hostname.includes('localhost')) {
    environment = 'staging'
}

const Utilites = {
    getSettings: ()=>{
        return {
            environment: environment,
            path: environment === 'production' ? '' : `.${environment}`,
            programId: environment === 'production' ? 1544 : 1544
        }
    }
}


export default Utilites;