import React from "react";
import { Col, Row } from 'react-bootstrap';
import logo from '../images/realpro-logo.png'
import { Link } from 'react-router-dom';
import installerLogo from '../images/installernet-logo.png'

const TwoLogos = () => {
    return (
        <Row className="mb-2 mt-2 col-10 offset-1">   
            <Col sm={6} xs={6} className="d-flex justify-content-start align-items-center">
                <Link to="/">
                    <img src={logo} alt="logo" />
                </Link>
            </Col>
            <Col sm={6} xs={6} className="d-flex justify-content-end">
                <img src={installerLogo} alt="logo" />
            </Col>
        </Row>
    )
}

export default TwoLogos;