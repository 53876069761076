import React, { Fragment } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import LoginForm from '../components/LoginForm';
import techpic from '../images/Picture2.png'
import HeaderService from '../components/HeaderService';
import './css/LoginPage.css'


const LoginPage = () => {

    return (
        <Fragment>
            <Container fluid style={{ border: '0', padding: '0' , flex: 'auto', display: 'flex',flexDirection: 'column'}}>
                <HeaderService />
                <Row style={{flex: 'auto', alignItems: 'center', width: '100%'}}>
                    <Col xs={12} md={6} className='container-column' >
                        <div>
                            <img src={techpic} className="techpic" alt='side' />
                        </div>
                    </Col>
                    <Col xs={12} md={6} className='container-column' style={{ border: '0', padding: '0' }}>
                        <Row style={{ height: '50%', maxWidth: '700px'}}>
                            <Card className="text-center" style={{ borderStyle: "hidden", border: '0', padding: '0' }}>
                                <Card.Header className='card-header' style={{ borderStyle: "hidden", fontSize: "max(1.5vw,1rem)", backgroundColor: "#55585a", color: 'white', borderRadius: '0' }}>REALTRUCK CSR</Card.Header>
                                <Card.Body>
                                    <Card.Title style={{ fontSize: "max(1.5vw,1rem)", padding: "0 20px 0 20px" }}> PROFESSIONAL INSTALLATION SERVICES FOR SELECT REALTRUCK PRODUCTS </Card.Title>
                                    <LoginForm />
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row style={{ height: '50%', maxWidth: '700px' }}>
                            <Card className="text-center" style={{ borderStyle: "hidden", border: '0', padding: '0' }}>
                                <Card.Header className='card-header' style={{ borderStyle: "hidden", fontSize: "max(1.5vw,1rem)", backgroundColor: "#55585a", color: 'white', borderRadius: '0' }}>INSTALLERS</Card.Header>
                                <Card.Body>
                                    <Card.Title style={{ fontSize: "max(1.5vw,1rem)", padding: "0 20px 0 20px" }}>REAL BENEFITS. REAL OPPORTUNITY. <br /> GROW YOUR BUSINESS.</Card.Title>
                                    <LoginForm />
                                </Card.Body>
                            </Card>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default LoginPage