import React from 'react';
import { Container, Row } from 'react-bootstrap';
import './css/Header.css'



const ServiceRebateBar = (props) => {

    return (
        <header className="App-header">
            <Container fluid>

                <Row className='Top-Bar align-items-center'>
                    <h3 className="mb-1 mt-2">{props.truckHeroLink} {props.realTruckLink}</h3>
                </Row>
            </Container>
        </header>
    )
}

export default ServiceRebateBar