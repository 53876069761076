import Utilites from "../components/Utilities";

let settings = Utilites.getSettings();

let serviceeventshost = `https://api.${ settings.environment === 'production' ? '' : `${settings.environment}.`}installernet.com/serviceevents/v3`;

const serviceEventsApi = {
    getHeaders: (token) => {
        return {
            "content-type": "application/json",
            "accept": "application/json",
            Authorization: `Bearer ${token}`
        };
    },
    getProgramProducts: async (token, programId) => {
        let results = await fetch(`${serviceeventshost}/programs/${programId}/products`, {headers: serviceEventsApi.getHeaders(token)});
        return results.json();
    }
}
export default serviceEventsApi;