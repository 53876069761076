import React from "react"

const Footer = (props) => {
    return (
        <div className='text-center p-4' style={props.style}>
            &copy; {(new Date().getFullYear())}&nbsp; InstallerNet & RealTruck
        </div>
    )
}

export default Footer